<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium">
            <el-form-item>
              <el-button type="primary" @click="showAddDialogForm"
                >新增角色</el-button
              >
            </el-form-item>
          </el-form>

          <el-form :inline="true" size="medium">
            <el-form-item>
              <el-button type="primary" @click="search">刷新列表</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      :height="tableHeight"
      border
      style="width: 100%"
    >
      <el-table-column prop="id" label="序号" type="index" width="80">
      </el-table-column>
      <el-table-column prop="roleName" label="角色名称" min-width="120">
      </el-table-column>
      <el-table-column prop="activate" label="激活状态" min-width="120">
        <template slot-scope="scope">
          <template v-if="scope.row.activate == 0"> 未激活 </template>
          <template v-else-if="scope.row.activate == 1"> 已激活 </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="操作"
        fixed="right"
        min-width="200"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="warning"
            @click="handleAllotRole(scope.$index, scope.row)"
            >分配资源</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :page-size="pageSize"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <el-dialog
      :title="editForm.id == 0 ? '新增角色' : '修改角色'"
      :visible.sync="editFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form :model="editForm" ref="editForm" :rules="editRules">
        <el-form-item
          label="角色名称"
          :label-width="labelWidth"
          prop="roleName"
        >
          <el-input v-model="editForm.roleName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="激活状态"
          :label-width="labelWidth"
          prop="activate"
        >
          <el-select v-model="editForm.activate" placeholder="请选择">
            <el-option :key="1" label="已激活" :value="1"></el-option>
            <el-option :key="0" label="未激活" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitForm"
          :loading="loading"
          >{{ editForm.id == 0 ? "添 加" : "修 改" }}</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="分配权限"
      :visible.sync="allotRoleResourceFormVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <el-form :model="editForm" ref="editForm" :rules="editRules">
        <!-- <el-form-item label="激活状态" :label-width="labelWidth" prop="activate"> -->
        <el-cascader-panel
          :options="options"
          :props="props"
          v-model="selectedOptions"
          :key="cascaderKey"
          @change="handleChange"
        ></el-cascader-panel>
        <!-- </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: 20px">
        <el-button @click="allotRoleResourceFormVisible = false"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click.native.prevent="submitAllotRoleResourceForm"
          :loading="loading"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { validPhone } from "@/common/utils/validate.js";
import request from "@/common/utils/request.js";
import util from "@/common/utils/util.js";
import {
  getRoleList,
  addRole,
  updateRole,
  deleteRole,
  getRoleResource,
  allotRoleResource,
} from "@/api/role";

export default {
  name: "userManage",
  data() {
    return {
      total: 0,
      pageSize: 10,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      editForm: {
        id: 0,
        roleName: "",
        activate: "",
      },
      editRules: {
        roleName: [
          { required: true, message: "请输角色名称", trigger: "blur" },
        ],
        activate: [
          { required: true, message: "请选择激活状态", trigger: "blur" },
        ],
      },
      loadingDiv: null,
      labelWidth: "100px",
      allotRoleResourceFormVisible: false,
      props: {
        multiple: true,
        value: "id",
        label: "title",
        // checkStrictly: true,
      },
      options: [],
      selectedOptions: [],
      allotRoleId: 0,
      cascaderKey: 0,
    };
  },
  watch: {
    "editForm.id": function (val) {
      if (this.editForm.id == 0) {
        this.editRules["password"] = [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      } else {
        this.editRules["password"] = [
          {
            required: false,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      }
    },
  },
  mounted() {
    this.getDataList(this.currentPage);

    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 198;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight = document.documentElement.clientHeight - 198;
      });
    };
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    handleAllotRole(index, row) {
      this.allotRoleId = row.id;
      this.options = [];
      this.selectedOptions = [];
      this.cascaderKey++; //key改变强制渲染
      this.loading = true;
      const loadingDiv = util.showLoading("加载中...");
      getRoleResource({ id: this.allotRoleId })
        .then((res) => {
          this.loading = false;
          loadingDiv.close();
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
            return;
          }

          this.options = res.data.resourceList;

          let resourceIds = res.data.resourceIds;

          let arr = [];
          let expandId = 0;

          // 处理role resouce 对应关系
          // 1级
          for (let i in this.options) {
            let item = this.options[i];
            if (i == 0) {
              expandId = item.id;
            }

            // 组合1级选中选项
            for (let l in resourceIds) {
              if (item.id == resourceIds[l]) {
                arr.push([item.id]);
              }
            }

            // 2级
            for (let j in item.children) {
              let item2 = item.children[j];
              if (i == 0 && j == 0) {
                expandId = item2.id;
              }

              // 组合2级选项
              for (let l in resourceIds) {
                if (item2.id == resourceIds[l]) {
                  arr.push([item.id, item2.id]);
                }
              }

              // 3级
              for (let k in item2.children) {
                let item3 = item2.children[k];
                if (i == 0 && j == 0 && k == 0) {
                  expandId = item3.id;
                }

                // 组合3级选项
                for (let l in resourceIds) {
                  if (item3.id == resourceIds[l]) {
                    arr.push([item.id, item2.id, item3.id]);
                  }
                }
              }
            }
          }

          //arr.unshift(this.expandId)
          this.selectedOptions = arr;

          this.allotRoleResourceFormVisible = true;
        })
        .catch((res) => {
          this.loading = false;
          loadingDiv.close();
        });
    },
    submitAllotRoleResourceForm() {
      let arr = [].concat.apply([], this.selectedOptions);

      arr = util.unique(arr);
      console.log(arr);
      arr.sort();

      this.loading = true;
      const loadingDiv = util.showLoading("分配权限中...");
      allotRoleResource({ id: this.allotRoleId, resourceIds: arr })
        .then((res) => {
          this.loading = false;
          loadingDiv.close();
          console.log(res.code == 1);

          if (res.code == 0) {
            this.$alert(res.msg, "分配权限失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "分配权限成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then(() => {
              this.allotRoleResourceFormVisible = false;
              // this.getDataList(1)
            });
          }
        })
        .catch((res) => {
          this.loading = false;
          loadingDiv.close();
        });
    },
    showAddDialogForm() {
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
      this.editForm.id = 0;
      this.editForm.roleName = "";
      this.editForm.activate = 1;

      this.editFormVisible = true;
    },
    handleEdit(index, row) {
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
      this.editForm.id = row.id;
      this.editForm.roleName = row.roleName;
      this.editForm.activate = row.activate;

      this.editFormVisible = true;
    },
    submitForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "添加中...";
          if (this.editForm.id) {
            loadingText = "修改中...";
          }
          const loadingDiv = util.showLoading(loadingText);
          let func = updateRole;
          if (this.editForm.id == 0) {
            func = addRole;
          }
          func(this.editForm)
            .then((res) => {
              let title = "修改";
              if (this.editForm.id == 0) {
                title = "添加";
              }
              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                  this.getDataList(1);
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handleDelete(index, row) {
      let loadingDiv;
      this.$confirm("是否确认删除角色:" + row.roleName, "删除确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("删除中...");
            return deleteRole({ id: row.id });
          }
        })
        .then((res) => {
          loadingDiv.close();
          if (res.code == 0) {
            this.$alert(res.msg, "删除失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "删除成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then((res) => {
              this.editFormVisible = false;
              this.getDataList(this.currentPage);
            });
          }
        })
        .catch((res) => {
          if (loadingDiv) {
            loadingDiv.close();
          }
        });
    },

    getDataList(pageNum) {
      const loadingDiv = util.showLoading();

      getRoleList({
        pageSize: this.pageSize,
        pageNum: pageNum,
      })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;
            this.currentPage = pageNum;
            this.total = data.total;
            this.tableData = data.dataList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.getDataList(pageNum);
    },
  },
};
</script>

<style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
<style lang="scss">
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}
</style>
